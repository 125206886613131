/* Make the effect more visible */
.host-partial-single-hour {
  border-left: 2px solid #e0e0e0;
  border-right: 2px solid #e0e0e0;
}

// Half-hour availability styles for weekly scheduler
.sh-availability {
  .time-block {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    overflow: visible;
    background-color: transparent !important; /* Override any existing background */
    
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 1px dotted #aaa;
      z-index: 1;
      pointer-events: none;
    }
    
    .half-block {
      flex: 1;
      width: 100%;
      height: 50%;
      transition: background-color 0.1s ease;
      background-color: transparent;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      
      &:hover {
        background-color: rgba(79, 129, 230, 0.3);
      }
      
      .time-label-display {
        color: white;
        font-size: 10px;
        font-weight: bold;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.9);
        pointer-events: none;
        user-select: none;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.25);
        padding: 1px 3px;
        border-radius: 2px;
        white-space: nowrap;
        max-width: 95%;
        overflow: hidden;
        
        @media (max-width: 767px) {
          font-size: 7px;
          padding: 1px 2px;
        }
      }
    }
    
    // All selected half-blocks use the same color
    &.first-half .top-half,
    &.second-half .bottom-half,
    &.full-hour .top-half,
    &.full-hour .bottom-half {
      background-color: #4f81e6;
    }
    
    &.full-hour::after {
      border-top-color: rgba(255, 255, 255, 0.7);
    }
  }
}